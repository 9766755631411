<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <van-form
      class="h-p-100 scroll-y w-p-100"
      @submit="onSubmit"
      ref="detailSubmit"
    >
      <van-cell-group inset class="h-calc-45 scroll-y margin-0 bg-transparent">
        <!-- <van-field name="uploader" label="现场照片">
                    <template #input>
                        <van-uploader :after-read="afterRead" v-model="fileList" preview-size="54px" :before-delete="previewdel"/>

                    </template>
                </van-field> -->

        <van-field name="uploader" label="现场照片" class="phonecell">
          <template #input>
            <div
              class="flex relative"
              v-for="(items, index) in fileList"
              :key="index"
            >
              <van-image
                @click="showImg(fileList)"
                :src="items.url"
                width="80px"
                height="80px"
              ></van-image>
              <div class="absolute r-0 h-20 w-20 flex ">
                <van-icon
                  name="cross"
                  size="20"
                  color="#ff0000"
                  @click="previewdel(index)"
                />
              </div>
            </div>
            <div class="w-p-100">
              <van-button size="mini" type="primary" @click="takephoto('null')"
                >拍照上传</van-button
              >
              <van-button size="mini" type="primary" @click="checkphoto('null')"
                >相册上传</van-button
              >
            </div>
          </template>
          <!-- <template #button>
                        
                    </template> -->
        </van-field>
        <van-field
          v-model="query.source"
          name="picker"
          label="隐患来源"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          readonly
          v-model="query.trouble_date"
          name="picker"
          label="发现时间"
          @click="showCalendar = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          is-link
          readonly
          v-model="state.finder"
          name="picker"
          label="发现人"
          @click="picker.finder = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          v-model="query.trouble_description"
          name="picker"
          label="隐患描述"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          v-model="query.measure"
          name="picker"
          label="整改措施"
          placeholder="选填项"
        />
        <van-field
          is-link
          readonly
          v-model="state.rectifier"
          name="picker"
          label="整改人"
          @click="picker.rectifier = true"
          placeholder="选填项"
        />
        <van-field
          is-link
          readonly
          v-model="state.trouble_tracker"
          name="picker"
          label="隐患跟踪人"
          @click="picker.trouble_tracker = true"
          placeholder="选填项"
        />
        <van-field
          is-link
          readonly
          v-model="state.issue"
          name="picker"
          label="隐患状态"
          @click="picker.issue = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          v-model="query.plan_handle_date"
          name="picker"
          label="计划处理时间"
          @click="showPlanCalendar = true"
          placeholder="选填项"
        />

        <!-- 下拉内容 -->
        <van-popup v-model:show="picker.finder" position="bottom">
          <van-search :placeholder="placeholder" v-model="finder_option_search" />
          <van-picker
            :columns="optionFilter(options.finder,finder_option_search)"
            @confirm="
              data => {
                confirm(data, 'finder');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.finder = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.rectifier" position="bottom">
          <van-search :placeholder="placeholder" v-model="rectifier_option_search" />
          <van-picker
            :columns="optionFilter(options.rectifier,rectifier_option_search)"
            @confirm="
              data => {
                confirm(data, 'rectifier');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.rectifier = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.trouble_tracker" position="bottom">
          <van-search :placeholder="placeholder" v-model="trouble_tracker_option_search" />
          <van-picker
            :columns="optionFilter(options.trouble_tracker,trouble_tracker_option_search)"
            @confirm="
              data => {
                confirm(data, 'trouble_tracker');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.trouble_tracker = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.issue" position="bottom">
          <van-picker
            :columns="options.issue"
            @confirm="
              data => {
                confirm(data, 'issue');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.issue = false"
          />
        </van-popup>
        <van-calendar
          v-model:show="showCalendar"
          @confirm="calendarOnConfirm"
          :min-date="new Date('2000-01-01')"
        />
        <van-calendar
          v-model:show="showPlanCalendar"
          @confirm="calendarPlanOnConfirm"
          :min-date="new Date('2000-01-01')"
        />
      </van-cell-group>
      <div class="flex">
        <van-button
          block
          type="default"
          class="bg-808080 color-fff"
          @click="
            () => {
              goToHistory = true;
              onSearch(1);
            }
          "
          >历史记录</van-button
        >
        <van-button
          block
          type="warning"
          @click="resetForm"
          v-if="query.finder != ''"
          >新增</van-button
        >
        <van-button
          block
          type="primary"
          :disabled="isloading"
          :loading="isloading"
          loading-text="提交中"
          native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <!--历史记录-->
    <van-popup
      v-model:show="goToHistory"
      position="bottom"
      style="height:calc(100% - 40px)"
      :closeable="false"
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
      :overlay="false"
    >
      <div>
        <van-search
          v-model="search.keyword"
          show-action
          background="#F3F3F3"
          @search="onSearch(1)"
          placeholder="关键字查询"
        >
          <template #action>
            <div class="flex alignCenter">
              <van-field
                is-link
                readonly
                class="searchInputBox"
                v-model="searchDisplay.issue"
                name="picker"
                placeholder="隐患状态"
                @click="showIssueOption = true"
              />
              <van-icon
                name="search"
                class="size-25"
                color="#0F60A7"
                @click="onSearch(1)"
              />

              <van-popup v-model:show="showIssueOption" position="bottom">
                <van-picker
                  :columns="options.issueSearch"
                  @confirm="
                    data => {
                      searchDisplay.issue = data.label;
                      search.issue = data.value;
                      showIssueOption = false;
                    }
                  "
                  :columns-field-names="{ text: 'label', value: 'value' }"
                  @cancel="showIssueOption = false"
                />
              </van-popup>
            </div>
          </template>
        </van-search>
      </div>
      <div class="h-calc-54">
        <van-pull-refresh
          v-model="loading"
          @refresh="refresh"
          @load="onLoad"
          :finished="finished"
          finished-text="没有更多了"
          class="h-calc-45 scroll-y"
        >
          <div v-if="list.length > 0" class="w-p-100 h-p-100">
            <div
              class="w-calc-48 Fbg lP-24 rP-24 bM-4 tP-5 bP-5 relative"
              v-for="item in list"
              :key="item"
            >
              <div class="h-45 flex alignCenter justifyBetween">
                <div class="size-20">{{ item.finder }}</div>
                <div class="size-18 color-80">{{ item.issue }}</div>
              </div>
              <div class="h-35 flex alignCenter size-14 color-80">
                {{ item.trouble_date }}
              </div>
              <div
                class="h-35 flex alignCenter justifyBetween size-14 color-80"
              >
                {{ item.source }}
                <div class="flex r-24 b-12 alignCenter justifyEnd">
                  <div
                    class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                    @click="getDetail(item)"
                  >
                    详情
                  </div>
                  <div
                    class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                    @click="del(item.id)"
                  >
                    删除
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty description="暂无数据"></van-empty>
          </div>
        </van-pull-refresh>
        <van-button
          block
          type="default"
          class="bg-808080 color-fff"
          @click="goToHistory = false"
          >关闭</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import { onMounted,reactive, ref} from 'vue';
import http from '../../../api/http';
import {Toast,Notify,Dialog ,ImagePreview} from 'vant'
import { remove } from '@vue/shared';
import dayjs from 'dayjs';
export default {
    setup() {
        const goToHistory = ref(false);
        const showIssueOption = ref(false);
        const finished = ref(false);
        const loading = ref(false);
        const list = ref([]);
        const fileList = ref([]);
        const showCalendar = ref(false);
        const showPlanCalendar = ref(false);
        const query = reactive({
            method:'saveorupdate',
            t:'CT_CONTRACTOR_TROUBLE_TRACING',
            ct_contractor_info_id: localStorage.getItem("ct_contractor_info_id"),
            attachment1: [],
            source:'',
            trouble_date:'',
            finder:'',
            trouble_description:'',
            measure:'', 
            rectifier:'',
            trouble_tracker:'',
            plan_handle_date:'',
            issue:'',
            id:''
        });
        const state = reactive({
            method:'saveorupdate',
            t:'CT_CONTRACTOR_TROUBLE_TRACING',
            attachment1:[],
            source:'',
            trouble_date:'',
            finder:'',
            trouble_description:'',
            measure:'', 
            rectifier:'',
            trouble_tracker:'',
            plan_handle_date:'',
            issue:'',
        });
        const finder_option_search = ref('')
        const rectifier_option_search = ref('')
        const trouble_tracker_option_search = ref('')
        const options = reactive({
            finder:[],
            rectifier:[],
            trouble_tracker:[],
            issue:[],
            issueSearch:[],
         });
         const optionFilter = (option,query) =>{
          if (query === null) {
            query = ''
          }
          if (option && option.length > 0) {
            const arr = option.filter((item) => item.label.includes(query))
            return arr;
          }
        }
         const picker = reactive({
            finder:false,
            rectifier:false,
            trouble_tracker:false,
            issue:false,
         });
         const search = reactive({
            issue:'',
            keyword:'',
            method:'query',
            queryId:1037,
            page:1,
            pageSize:20
         })
         const searchDisplay = reactive({
            issue: '',
         })
        const imgUrl = http.getImgUrl();
        const detailSubmit = ref(null);

        //图片上传完成
        const afterRead = (data) => {
            let formData = new FormData();
            formData.append('file', data.file, data.file.name);
            http.fileuoload(formData)
            .then(res=>{
                data.status = 'success';
                query.attachment1.push({document_path:res});
                fileList.value.forEach((elem,index)=>{
                    if(elem.status == 'success'){
                        fileList.value.splice(index,1)
                    }
                });
                fileList.value.push({url:imgUrl + res});
            })
            .catch(()=>{
                data.status = 'faild';
                fileList.value.forEach((elem,index)=>{
                    if(!elem.status || elem.status == 'faild'){
                        fileList.value.splice(index,1)
                    }
                });
            })
        };

        //下拉接口
        const getSelectList = () =>{
             http.get({
                 method:'listMultiPardata',
                 t:'CT_CONTRACTOR_TROUBLE_TRACING',
                 prop:'finder,rectifier,trouble_tracker,issue',
             })
             .then(res=>{
                var allIssue = [{label:"全部",value:""}]
                options.finder=res.finder
                options.rectifier=res.rectifier
                options.trouble_tracker=res.trouble_tracker
                options.issue = res.issue
                options.issueSearch = allIssue.concat(res.issue);
             })
         }
        
        const confirm = (data,type) =>{
            query[type] = data.value;
            state[type] = data.label;
            picker[type] = false;        
        }
        const isloading = ref(false);
        const onSubmit = () =>{
            Dialog.confirm({
                title: '提示',
                message:'此操作将提交此次的违规，请确认？',
            })
            .then(()=>{
                isloading.value = true;
                http.post(query)
                .then(()=>{
                    isloading.value = false;
                    Notify({ type: 'success', message: '保存成功' });
                })
                .catch(()=>{
                    isloading.value = false;
                })
            })
            .catch(()=>{});  
        }
        const onSearch = (page) =>{
            search.page = page;
            loading.value = false;
            Toast.loading({message: '加载中...',duration:0});
            http.get(search)
            .then(res=>{
                Toast.clear();
                list.value = res.map;
            })
            // finished.value = true;
        }

        // 下拉刷新
        const refresh = () =>{
            loading.value = true;
            onSearch(1)
        }
        //删除
        const del = (ids) =>{
            Dialog.confirm({
                message:'此操作将删除所选数据,请确认？',
            })
            .then(() => {
                http.post({
                    method:'delete',
                    t:'CT_CONTRACTOR_TROUBLE_TRACING',
                    ids:ids
                })
                .then(()=>{
                    onSearch(1);
                })
            })
            .catch(() => {});            
        }

        // 详情
        
        const getDetail = (item) =>{
            goToHistory.value = false;
             http.get({
                 method:'detail',
                 t:'CT_CONTRACTOR_TROUBLE_TRACING',
                 id:item.id
             })
             .then(res=>{
                var flist = []
                 var dMap = res.map;
                 Object.keys(dMap).map(key=>{
                    query[key] = dMap[key]
                 });
                 if(dMap.attachment1 && dMap.attachment1.length > 0){
                    dMap.attachment1.forEach(element => {
                        flist.push({url:imgUrl + element.document_path})
                    });
                 }
                 fileList.value = flist;
                 state.finder = item.finder;
                 state.rectifier = item.rectifier;
                 state.trouble_tracker = item.trouble_tracker;
                 state.issue = item.issue;
             })
        }
        const previewdel = (num) =>{
            fileList.value.forEach((elem,index)=>{
                if(num == index){
                    fileList.value.splice(index,1);
                    query.attachment1.splice(index,1)
                }
            });
        }

        const showImg = (list) =>{
            var images = [];
            list.forEach(elem =>{
                images.push(elem.url);
            });
            ImagePreview({
                images: images,
                closeable:true,
                startPosition: 1,
                loop:true
            });
        }
        const imageurl = ref('');
        const onSuccess = (data) =>{
            imageurl.value = "data:image/png;base64," + data
            var file = http.dataURLtoFile(imageurl.value);
            let formData = new FormData();
            formData.append('file', file, file.name);
            http.fileuoload(formData)
            .then(res=>{
                query.attachment1.push({document_path:res});
                fileList.value.forEach((elem,index)=>{
                    if(elem.status == 'success'){
                        fileList.value.splice(index,1)
                    }
                });
                fileList.value.push({url:imgUrl + res});
                http.loading.close()
            })
            .catch(()=>{
                data.status = 'faild';
                fileList.value.forEach((elem,index)=>{
                    if(!elem.status || elem.status == 'faild'){
                        fileList.value.splice(index,1)
                    }
                });
                http.loading.close()
            })
        }
        // 拍照
        const takephoto = (index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,index)
                }, 
                function(err){
                    alert(err.toString())
                }, 
                {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,//返回FILE_URI类型
                    sourceType: Camera.PictureSourceType.CAMERA,//指定图片来自拍照
                    // cameraDirection: Camera.Direction.FRONT,//指定前后摄像头--好像没起作用
                    encodingType: Camera.EncodingType.PNG,
                    targetWidth: 150,
                    targetHeight: 150,
                    saveToPhotoAlbum:false
                });
        }
        // 从相册选择
        const checkphoto = (index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,index)
                }, 
                function(err){
                    alert(err.toString())
                }, {
                quality: 50,
                destinationType: Camera.DestinationType.DATA_URL,//返回Base64编码字符串
                sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,//指定图片来自相册
                encodingType: Camera.EncodingType.PNG,//指定返回图片是png格式还是jpeg
                targetWidth: 150,
                targetHeight: 150
            });
        }

        const calendarOnConfirm = (date) =>{
            query.trouble_date = dayjs(date).format('YYYY-MM-DD');
            showCalendar.value = false;
        }
        const calendarPlanOnConfirm = (date) =>{
            query.plan_handle_date = dayjs(date).format('YYYY-MM-DD');
            showPlanCalendar.value = false;
        }

        const resetForm = ()=>{
            Object.keys(query).map(key=>{
                if(key!='method'&&key!='t'&&key!='ct_contractor_info_id'){
                    if(key=='attachment1'){
                        query[key]=[]
                        fileList.value = []
                    } else {
                        query[key]=''
                        state[key]=''
                    }
                }
            });
        }

        onMounted(()=>{
             getSelectList();
         });
        return {
            onSubmit,
            onSearch,
            options,
            query,
            search,
            state,
            picker,
            detailSubmit,
            confirm,
            refresh,
            goToHistory,
            getSelectList,
            afterRead,
            showIssueOption,
            finished,
            loading,
            list,
            del,
            getDetail,
            searchDisplay,
            fileList,
            previewdel,
            showCalendar,
            showPlanCalendar,
            calendarOnConfirm,
            calendarPlanOnConfirm,
            takephoto,
            checkphoto,
            showImg,
            resetForm,
            isloading,
            finder_option_search,
            rectifier_option_search,
            trouble_tracker_option_search,
            optionFilter
        }
    },
}
</script>